export function formatAMPM(date: Date) {
  let hours = date.getHours();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  let minutes: string | number = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return hours + ":" + minutes + ampm;
}

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
