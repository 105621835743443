import { PropsWithChildren, createContext, useContext, Dispatch, useReducer } from "react";
import produce from "immer";
import { CrewMember, VehicleInfo } from "../types";

/**
 * State
 */

export interface VehicleState {
  readyState: ReadyState;
  vehicleInfo: VehicleInfo | null;
  crewMembers: CrewMember[] | null;
}

export enum ReadyState {
  LOGGED_OUT = "Logged off MDT",
  LOGGED_IN = "Logged on MDT",
  OFF_DUTY = "Off duty",
}

const initialState: VehicleState = {
  readyState: ReadyState.LOGGED_OUT,
  vehicleInfo: null,
  crewMembers: null,
};

/**
 * Reducer
 */

type VehicleAction =
  | { type: "LOGIN_TO_VEHICLE"; vehicleInfo: VehicleInfo; crewMembers: CrewMember[] }
  | { type: "LOGOUT_OF_VEHICLE" }
  | { type: "CREW_OFF_DUTY" };

const reducer = (state: VehicleState, action: VehicleAction): VehicleState => {
  const type = action.type;
  switch (type) {
    case "LOGIN_TO_VEHICLE":
      return produce(state, (draft) => {
        draft.readyState = ReadyState.LOGGED_IN;
        draft.vehicleInfo = action.vehicleInfo;
        draft.crewMembers = action.crewMembers;
      });

    case "CREW_OFF_DUTY":
      return produce(state, (draft) => {
        draft.readyState = ReadyState.OFF_DUTY;
        draft.vehicleInfo = null;
        draft.crewMembers = null;
      });

    case "LOGOUT_OF_VEHICLE":
      return produce(state, (draft) => {
        draft.readyState = ReadyState.LOGGED_OUT;
        draft.vehicleInfo = null;
        draft.crewMembers = null;
      });

    default:
      console.warn(`VehicleAction ${type} not supported`);
      return state;
  }
};

const vehicleActions = {
  loginToVehicle: (dispatch: Dispatch<VehicleAction>, vehicleInfo: VehicleInfo, crewMembers: CrewMember[]) => {
    dispatch({ type: "LOGIN_TO_VEHICLE", vehicleInfo, crewMembers });
  },
  logoutOfVehicle: (dispatch: Dispatch<VehicleAction>) => {
    dispatch({ type: "LOGOUT_OF_VEHICLE" });
  },
  crewOffDuty: (dispatch: Dispatch<VehicleAction>) => {
    dispatch({ type: "CREW_OFF_DUTY" });
  },
};

/**
 * Context
 */

const VehicleStateContext = createContext<VehicleState>(undefined!);
const VehicleDispatchContext = createContext<Dispatch<VehicleAction>>(undefined!);

export const VehicleProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <VehicleStateContext.Provider value={state}>
      <VehicleDispatchContext.Provider value={dispatch}>{children}</VehicleDispatchContext.Provider>
    </VehicleStateContext.Provider>
  );
};

const useVehicleState = (): VehicleState => {
  const context = useContext(VehicleStateContext);
  if (context === undefined) {
    throw new Error("useVehicleState must be within VehicleProvider");
  }
  return context;
};

const useVehicleDispatch = (): Dispatch<VehicleAction> => {
  const context = useContext(VehicleDispatchContext);
  if (context === undefined) {
    throw new Error("useVehicleDispatch must be within VehicleProvider");
  }
  return context;
};

export { useVehicleState, useVehicleDispatch, vehicleActions };
